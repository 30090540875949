import { makeStyles } from '@material-ui/core'
import cx from 'clsx'
import theme from '../theme'

const useStyles = makeStyles({
  root: (({ minHeight }: { minHeight?: string | number }) => ({
    minHeight,
    margin: 0,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
      minHeight: 'auto',
    },
  })),
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  first: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 78px)',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Screen = ({
  centered = true,
  first = false,
  children = null,
  className = '',
  style = null,
  minHeight = '100vh',
  ...other
}) => {
  const cls = useStyles({ minHeight })
  return (
    <div {...other} className={`${cx(cls.root, centered && cls.centered, first && cls.first)} ${className}`} style={{
      // outline: '10px #f00 solid',
      ...style,
    }}>
      {children}
    </div>
  )
}

export default Screen
