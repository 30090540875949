import Link from 'next/link'

const Logo = ({
  width = 100,
  height = width / 4,
  onClick = null,
}) => {
  return (
    <Link href='/'>
      <svg onClick={onClick} style={{ cursor: 'pointer',
      }} width={width} height={height} viewBox='0 0 102 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M22.2212 6.60982C19.4189 6.60982 17.0451 7.73913 15.6274 9.56597C14.4075 7.57306 12.2645 6.60982 9.69294 6.60982C7.28619 6.60982 5.2421 7.47342 3.92333 9.06774V6.80911H0V24.546H4.12115V15.5447C4.12115 12.0239 5.96742 10.2303 8.70387 10.2303C11.2095 10.2303 12.6602 11.7582 12.6602 14.9136V24.546H16.7813V15.5447C16.7813 12.0239 18.6606 10.2303 21.364 10.2303C23.8697 10.2303 25.3203 11.7582 25.3203 14.9136V24.546H29.4415V14.3822C29.4415 9.03453 26.4743 6.60982 22.2212 6.60982Z' fill='#061226' />
        <path d='M39.5504 4.01904C40.6714 4.01904 41.5286 3.18866 41.5286 2.02612C41.5286 0.830378 40.6714 0 39.5504 0C38.4295 0 37.5723 0.830378 37.5723 2.02612C37.5723 3.18866 38.4295 4.01904 39.5504 4.01904ZM45.9464 4.01904C47.0674 4.01904 47.9246 3.18866 47.9246 2.02612C47.9246 0.830378 47.0674 0 45.9464 0C44.8255 0 43.9683 0.830378 43.9683 2.02612C43.9683 3.18866 44.8255 4.01904 45.9464 4.01904Z' fill='#061226' />
        <path d='M79.4393 6.80911L74.6587 19.9623L69.9112 6.80911H66.4494L61.6029 19.8959L56.9543 6.80911H53.0639L59.5259 24.546H63.4822L68.0979 12.3228L72.6476 24.546H76.6039L83.0989 6.80911H79.4393Z' fill='#061226' />
        <path d='M102 15.7772C102 10.2303 98.2415 6.60982 93.0983 6.60982C87.8562 6.60982 83.9988 10.3963 83.9988 15.6776C83.9988 20.9588 87.8232 24.7785 93.7247 24.7785C96.7579 24.7785 99.2306 23.7821 100.813 21.8888L98.6042 19.3312C97.3513 20.6266 95.8018 21.2577 93.8236 21.2577C90.7575 21.2577 88.6145 19.597 88.12 17.0062H101.934C101.967 16.6076 102 16.1094 102 15.7772ZM93.0983 9.93134C95.8018 9.93134 97.747 11.6917 98.0767 14.2493H88.087C88.4826 11.6585 90.4278 9.93134 93.0983 9.93134Z' fill='#061226' />
        <path d='M42.9257 24.9998C48.3986 24.9998 52.322 21.2132 52.322 15.8988C52.322 10.5844 48.3986 6.83105 42.9257 6.83105C37.5188 6.83105 33.5625 10.5844 33.5625 15.8988C33.5625 21.2132 37.5188 24.9998 42.9257 24.9998ZM42.9257 21.4457C39.9585 21.4457 37.7166 19.2867 37.7166 15.8988C37.7166 12.5108 39.9585 10.3519 42.9257 10.3519C45.9259 10.3519 48.1679 12.5108 48.1679 15.8988C48.1679 19.2867 45.9259 21.4457 42.9257 21.4457Z' fill='#FFD600' />
      </svg>
    </Link>
  )
}

export default Logo