import { makeStyles } from '@material-ui/core'

export const footerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('xl')]: {
      padding: '3rem 0 4.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '2.5rem 0 3.25rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0 2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1.5rem 0 1.5rem',
    },
  },
  logo: {
    order: 1,
    marginRight: 'auto',
  },
  nav: {
    display: 'flex',
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'row',
      order: 2,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'red',
      order: -1,
      width: '100%',
      marginBottom: '.5rem',
    },
  },
  navItem: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '.875rem',
    lineHeight: 1,
    paddingBottom: 1,
    borderBottom: '1px solid rgba(0,0,0,.12)',
    [theme.breakpoints.down('xl')]: {
      margin: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 1.5rem',
    },
    transition: 'all .15s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  navItemActive: {
    fontWeight: 'bold',
    borderColor: theme.palette.primary.main,
  },
  socials: {
    order: 3,
    marginLeft: '2rem',
  },
  social: {
    margin: '.125rem',
  },
}))