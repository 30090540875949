import { Container } from '@material-ui/core'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { footerStyles } from './styles'
import { FB, Instagram, LinkedIn, SOCIAL_URLS } from '@/components/Socials'

const NAV = [
  { href: '/datenschutz', label: 'Datenschutzerklärung' },
  { href: '/impressum', label: 'Impressum' },
  { href: '/contacts', label: 'Kontakte' },
]

const Footer: React.FC = () => {
  const classes = footerStyles()
  const Router = useRouter()

  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.logo}>
          <Link href='/'>
            <a>
              <Logo />
            </a>
          </Link>
        </div>

        <div className={classes.nav}>
          {NAV.map((item, i) => (
            <Link href={item.href} key={i}>
              <a className={clsx({
                [classes.navItem]: true,
                [classes.navItemActive]: item.href === Router.pathname,
              })}>{item.label}</a>
            </Link>
          ))}
        </div>

        <div className={classes.socials}>
          <a href={SOCIAL_URLS.instagram} className={classes.social} target='_blank' rel='noreferrer'>
            <Instagram />
          </a>
          <a href={SOCIAL_URLS.fb} className={classes.social} target='_blank' rel='noreferrer'>
            <FB />
          </a>
          <a href={SOCIAL_URLS.linkedIn} className={classes.social} target='_blank' rel='noreferrer'>
            <LinkedIn />
          </a>
        </div>
      </div>
    </Container>
  )
}

export default Footer


const Logo: React.FC = () => (
  <svg width='18' height='24' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.91083 3.83999C6.99363 3.83999 7.82165 3.04661 7.82165 1.93586C7.82165 0.793386 6.99363 0 5.91083 0C4.82802 0 4 0.793386 4 1.93586C4 3.04661 4.82802 3.83999 5.91083 3.83999ZM12.0892 3.83999C13.172 3.83999 14 3.04661 14 1.93586C14 0.793386 13.172 0 12.0892 0C11.0064 0 10.1783 0.793386 10.1783 1.93586C10.1783 3.04661 11.0064 3.83999 12.0892 3.83999Z' fill='black'/>
    <path d='M8.98418 24C14.2355 24 18 20.3987 18 15.3442C18 10.2897 14.2355 6.71997 8.98418 6.71997C3.79613 6.71997 0 10.2897 0 15.3442C0 20.3987 3.79613 24 8.98418 24ZM8.98418 20.6198C6.13708 20.6198 3.98594 18.5664 3.98594 15.3442C3.98594 12.1219 6.13708 10.0686 8.98418 10.0686C11.8629 10.0686 14.0141 12.1219 14.0141 15.3442C14.0141 18.5664 11.8629 20.6198 8.98418 20.6198Z' fill='#FFD600'/>
  </svg>
)