import { makeStyles } from '@material-ui/core'
import theme from '../../theme'
import cx from 'clsx'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    color: '#061226',
    textDecoration: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    transition: 'border-bottom-color 0.4s ease',
    '&:hover': {
      borderBottomColor: '#FFD600',
    },
    '&.active': {
      fontWeight: 'bold',
      borderBottomColor: '#FFD600',
    },
    // '$nav&': {
    //
    // },
  },
  nav: {
    marginRight: '32px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '15px',
    },
  },
})

const Link = ({
  title = '',
  href = '',
  className = '',
  target = '',
  nav = false,
  ...other
}) => {
  const cls = useStyles()
  return (
    <a className={`${cx({
      [cls.root]: true,
      [cls.nav]: !!nav,
    })} ${className}`} href={href} target={target} {...other}>{title}</a>
  )
}

export default Link
