import { IconButton, SvgIcon } from '@material-ui/core'

const SOCIAL_URLS = {
  fb: 'https://www.facebook.com/moewe.studio/',
  instagram: 'https://www.instagram.com/moewe.studio/',
  linkedIn: 'http://www.linkedin.com/in/moewe-studio-germany',
}

const Instagram: React.FC = () => (
  <IconButton>
    <SvgIcon viewBox='0 0 18 18'>
      <path d='M17.9543 5.29207C17.9121 4.33565 17.7574 3.67813 17.5358 3.10837C17.3072 2.50359 16.9555 1.96214 16.4948 1.51202C16.0445 1.0549 15.4994 0.699693 14.9015 0.474704C14.3284 0.253148 13.6741 0.0984845 12.7175 0.0563161C11.7538 0.0105764 11.4478 0 9.00353 0C6.55921 0 6.25326 0.0105764 5.29309 0.0527448C4.33649 0.0949132 3.67884 0.249714 3.10911 0.471132C2.50408 0.699693 1.96252 1.05133 1.51231 1.51202C1.0551 1.96214 0.699966 2.50717 0.474796 3.10494C0.253197 3.67813 0.0985036 4.33208 0.056327 5.28849C0.0105785 6.25205 0 6.55794 0 9.00179C0 11.4456 0.0105785 11.7515 0.0527551 12.7115C0.0949317 13.6679 0.249762 14.3254 0.471361 14.8952C0.699966 15.5 1.0551 16.0414 1.51231 16.4916C1.96252 16.9487 2.50765 17.3039 3.10554 17.5289C3.67884 17.7504 4.33292 17.9051 5.28966 17.9473C6.24969 17.9896 6.55578 18 9.0001 18C11.4444 18 11.7504 17.9896 12.7105 17.9473C13.6671 17.9051 14.3248 17.7504 14.8945 17.5289C16.1044 17.0612 17.061 16.1048 17.5288 14.8952C17.7503 14.322 17.9051 13.6679 17.9473 12.7115C17.9895 11.7515 18.0001 11.4456 18.0001 9.00179C18.0001 6.55794 17.9965 6.25205 17.9543 5.29207Z' fill='black'/>
      <path d='M9.00352 4.37784C6.45026 4.37784 4.37866 6.4489 4.37866 9.0018C4.37866 11.5547 6.45026 13.6258 9.00352 13.6258C11.5569 13.6258 13.6284 11.5547 13.6284 9.0018C13.6284 6.4489 11.5569 4.37784 9.00352 4.37784ZM9.00352 12.0013C7.3471 12.0013 6.00349 10.658 6.00349 9.0018C6.00349 7.34556 7.3471 6.00235 9.00352 6.00235C10.6601 6.00235 12.0036 7.34556 12.0036 9.0018C12.0036 10.658 10.6601 12.0013 9.00352 12.0013Z' fill='white'/>
      <path d='M14.891 4.19505C14.891 4.79118 14.4075 5.27454 13.8111 5.27454C13.2149 5.27454 12.7314 4.79118 12.7314 4.19505C12.7314 3.59879 13.2149 3.11557 13.8111 3.11557C14.4075 3.11557 14.891 3.59879 14.891 4.19505Z' fill='white'/>
    </SvgIcon>
  </IconButton>
)

const LinkedIn: React.FC = () => (
  <IconButton>
    <SvgIcon viewBox='0 0 18 18'>
      <path d='M17.9964 18V17.9993H18.0009V11.3978C18.0009 8.16829 17.3057 5.68054 13.5302 5.68054C11.7152 5.68054 10.4972 6.67654 9.99993 7.62079H9.94743V5.98204H6.36768V17.9993H10.0952V12.0488C10.0952 10.482 10.3922 8.96704 12.3324 8.96704C14.2442 8.96704 14.2727 10.755 14.2727 12.1493V18H17.9964Z' fill='black'/>
      <path d='M0.297852 5.98273H4.02985V18H0.297852V5.98273Z' fill='black'/>
      <path d='M2.16235 0C0.969105 0 0.000854492 0.96825 0.000854492 2.1615C0.000854492 3.35475 0.969105 4.34325 2.16235 4.34325C3.3556 4.34325 4.32385 3.35475 4.32385 2.1615C4.3231 0.96825 3.35485 0 2.16235 0V0Z' fill='black'/>
    </SvgIcon>
  </IconButton>
)

const FB: React.FC = () => (
  <IconButton>
    <SvgIcon viewBox='0 0 9 18'>
      <path d='M7.3575 2.98875H9.00075V0.12675C8.71725 0.08775 7.74225 0 6.60675 0C4.2375 0 2.6145 1.49025 2.6145 4.22925V6.75H0V9.9495H2.6145V18H5.82V9.95025H8.32875L8.727 6.75075H5.81925V4.5465C5.82 3.62175 6.069 2.98875 7.3575 2.98875Z' fill='black'/>
    </SvgIcon>
  </IconButton>
)

export {
  SOCIAL_URLS,
  Instagram,
  LinkedIn,
  FB,
}