import clsx from 'clsx'
import Link from './shared/Link'
import { useRouter } from 'next/router'
import { makeStyles } from '@material-ui/core'


export const useTurboButtonStyles = makeStyles({
  root: {
    borderRadius: 16,
    border: 'none !important',
    color: '#fff !important',
    padding: '.5rem 1rem',
    background: 'linear-gradient(90deg, #2D0AF5 0%, #5398F8 100%)',
    boxShadow: '0 24px 60px rgba(65, 82, 247, 0.32)',
  },
})

const Nav = ({ cls, showNav, isMobile = false }) => {
  const { pathname } = useRouter()
  const turbocls = useTurboButtonStyles()

  return (
    <div className={clsx(cls.nav, showNav && cls.showNav)}>
      <Link className={pathname === '/team' && 'active'} title={'Team'} href={'/team'} nav />
      <Link className={pathname === '/services' && 'active'} title={'Dienstleistungen'} href={'/services'} nav />
      <Link className={pathname === '/projects' && 'active'} title={'Projekte'} href={'/projects'} nav />
      <Link className={pathname === '/contacts' && 'active'} title={'Kontakte'} href={'/contacts'} nav />
      <Link className={pathname === '/blog' && 'active'} title={'Blog'} href={'/blog'} nav />

      {!isMobile && <Link
        className={turbocls.root}
        title={'Turbo'}
        href={'/turbo'}
      />}

    </div>
  )
}

export default Nav
