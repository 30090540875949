import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import MainLogo from './MainLogo'
import Nav from './Nav'
import IconBurgerAnimated from './Icons/IconBurgerAnimated'
import theme from '../theme'
import Link from './shared/Link'
import { useTurboButtonStyles } from './Nav'


const useStyles = makeStyles({
  header: {
    position: 'fixed',
    backdropFilter: 'blur(8px)',
    background: 'rgba(255, 255, 255, 0.9)',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
    },
  },
  headerFixed: {
    height: '100%',
    zIndex: 101,
    borderBottom: 'none',
  },
  root: {
    padding: '1rem 0',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  nav: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      margin: '0',
      width: '100%',
      flexDirection: 'column',
      padding: '1.2143rem 0 1rem',
      alignItems: 'flex-start',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& > a': {
        marginRight: 0,
        marginTop: '1.5rem',
      },
    },
  },
  showNav: {
    display: 'flex',
  },
  logo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
})


const getHeaderHeight = (anchor) => {
  const rect = anchor.current.getBoundingClientRect()
  return `${rect.height}px`
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Header = ({ labels = {} as any, style = null }) => {
  const cls = useStyles()

  const headerRef = useRef(null)
  const [showNav, setShowNav] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!isTablet && headerRef.current) {
      const headerHeight = getHeaderHeight(headerRef)
      document.body.style.paddingTop = headerHeight
    }

    return onNavClose()
  }, [])


  // to think about more elegent solution
  useEffect(() => {
    if (!isMobile && showNav) { onNavClose() }
  })

  const onNavOpen = () => {
    if (showNav) {
      onNavClose()
      return
    }

    document.body.style.overflow = 'hidden'
    setShowNav(true)
  }

  const onNavClose = () => {
    document.body.style.overflow = 'visible'
    setShowNav(false)
  }

  const turbocls = useTurboButtonStyles()

  return (
    <>
      <header className={clsx(cls.header, showNav && cls.headerFixed)} ref={headerRef}>
        <Container>
          <div className={cls.root}>
            <div className={cls.logo}>
              <MainLogo onClick={onNavClose} isMobile={isMobile} />
              {isMobile && (
                <Link
                  style={{ marginLeft: '50%' }}
                  className={turbocls.root}
                  title={labels.cta_main_to_turbo}
                  href={'/turbo'}
                />
              )}
              {isMobile && <IconBurgerAnimated isOpen={showNav} onClick={onNavOpen} />}
            </div>
            <Nav cls={cls} showNav={showNav} isMobile={isMobile} />
          </div>
        </Container>
      </header>
    </>
  )
}

export default Header
